<template>
  <div>
    <!-- Start Content  -->
    <!-- Start Header  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-3 page-title">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("MENU.RECENT_TRANSACTION")
          }}</span>
        </h3>
      </div>
      <!-- End Header  -->
      <div class="card-body py-0">
        <!-- Start Search Filter -->
        <form class="form">
          <div class="row form-group">
            <div class="col-md-12">
              <div class="row pb-2 align-items-end">
                <div class="col-md-2">
                  <label class="form-label mb-1">{{
                    $t("TRANSACTION.START_UP_DATE")
                  }}</label>
                  <b-datepicker
                    :placeholder="$t('TRANSACTION.NO_DATE')"
                    size="sm"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit'
                    }"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    v-model="search.date_from"
                  ></b-datepicker>
                </div>

                <div class="col-md-2 align-items-bottom">
                  <label class="form-label mb-1">{{
                    $t("TRANSACTION.END_UP_DATE")
                  }}</label>
                  <b-datepicker
                    :placeholder="$t('TRANSACTION.NO_DATE')"
                    size="sm"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit'
                    }"
                    today-button
                    reset-button
                    close-button
                    locale="en"
                    v-model="search.date_to"
                  ></b-datepicker>
                </div>

                <div class="col-md-2">
                  <label class="form-label mb-1">{{ $t("TABLE.TYPE") }}</label>
                  <b-select class="py-0" size="sm" v-model="search.type">
                    <b-select-option value="ALL">{{
                      $t("TRANSACTION.ALL")
                    }}</b-select-option>
                    <b-select-option value="WITHDRAW">{{
                      this.$t("MENU.WITHDRAWAL")
                    }}</b-select-option>
                    <b-select-option value="DEPOSIT">{{
                      this.$t("MENU.DEPOSIT")
                    }}</b-select-option>
                  </b-select>
                </div>

                <div class="col-md-2">
                  <label class="form-label mb-1">{{
                    $t("TRANSACTION.TOKEN_TYPE")
                  }}</label>
                  <b-select class="py-0" size="sm" v-model="search.token_type">
                    <b-select-option value="ALL">{{
                      $t("TRANSACTION.ALL")
                    }}</b-select-option>
                    <b-select-option value="ETH">ETH</b-select-option>
                    <b-select-option value="BNB">BNB</b-select-option>
                  </b-select>
                </div>

                <div class="col-md-2">
                  <label class="form-label mb-1">{{
                    $t("TRANSACTION.TOKEN_NAME")
                  }}</label>
                  <b-select class="py-0" size="sm" v-model="search.token_name">
                    <b-select-option value="ALL">{{
                      $t("TRANSACTION.ALL")
                    }}</b-select-option>
                    <b-select-option value="USDT">USDT</b-select-option>
                    <b-select-option value="BUSD">BUSD</b-select-option>
                  </b-select>
                </div>

                <div class="col-md-2">
                  <label class="form-label mb-1">{{
                    $t("TRANSACTION.STATUS")
                  }}</label>
                  <b-select class="py-0" size="sm" v-model="search.status">
                    <b-select-option value="ALL">{{
                      $t("TRANSACTION.ALL")
                    }}</b-select-option>
                    <b-select-option value="PENDING">{{
                      $t("TRANSACTION.PENDING")
                    }}</b-select-option>
                    <b-select-option value="SUCCESS">{{
                      $t("TRANSACTION.SUCCESS")
                    }}</b-select-option>
                    <b-select-option value="FAILED">{{
                      $t("TRANSACTION.FAILED")
                    }}</b-select-option>
                  </b-select>
                </div>

                <div class="col-md-3 pt-3">
                  <label class="form-label mb-1">{{
                    $t("TRANSACTION.SEARCH_FILTER")
                  }}</label>
                  <b-input size="sm" v-model="search.search"></b-input>
                </div>

                <div class="col-md-3">
                  <label class="form-label mb-1">{{
                    $t("TRANSACTION.SENDER_ADDRESS")
                  }}</label>
                  <b-input size="sm" v-model="search.sender_address"></b-input>
                </div>

                <div class="col-md-4">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="getTransaction(1)"
                    >{{ $t("TRANSACTION.SEARCH") }}</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- End Search Filter  -->
      </div>
    </div>

    <!-- Start List  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pb-0">
        <div class="d-flex justify-content-between align-items-start pb-3">
          <div>
            <label class="form-label">{{ $t("TABLE.PER_PAGE") }}</label>
            <b-select
              size="sm"
              v-model="pagination.limit"
              @change="getTransaction(1)"
            >
              <b-select-option value="50">50</b-select-option>
              <b-select-option value="100">100</b-select-option>
              <b-select-option value="150">150</b-select-option>
            </b-select>
          </div>
          <b-button
            variant="primary"
            size="sm"
            v-if="items.length !== 0"
            @click="exportMethod()"
            >{{ $t("TRANSACTION.EXPORT") }}</b-button
          >
        </div>
        <!-- <b-table class="bg-white" striped hover :items="items"> -->
        <b-table class="bg-white" striped hover :fields="fields" :items="items">
          <!-- Set No Data when none of item -->
          <template #bottom-row="items" v-if="items.length === 0">
            <b-td :colspan="fields.length" class="text-center">{{
              $t("TABLE.NO_DATA")
            }}</b-td>
          </template>
          <template #cell(sn)="data">
            <div v-if="data.item.sn">
              ...{{ data.item.sn.substr(data.item.sn.length - 8) }}
              <a href="javascript:void(0)" @click="copy(data.item.sn)">
                <img src="@/assets/img/copy.png" width="15px" />
              </a>
            </div>
          </template>
          <template #cell(type)="data">
            {{ $t(`MENU.${data.item.type}`) }}
          </template>
          <template #cell(sender_address)="data">
            <div v-if="data.item.sender_address">
              ...{{
                data.item.sender_address.substr(
                  data.item.sender_address.length - 8
                )
              }}
              <a
                href="javascript:void(0)"
                @click="copy(data.item.sender_address)"
              >
                <img src="@/assets/img/copy.png" width="15px" />
              </a>
            </div>
          </template>
          <template #cell(status)="data">
            {{ $t(`TABLE.${data.item.status}`) }}
          </template>
          <template #cell(date_time)="data">
            {{ data.item.date_time | moment("DD-MM-YYYY HH:mm:ss") }}
          </template>
        </b-table>
        <!-- Start Pagination  -->
        <div class="overflow-auto">
          <b-pagination
            v-model="pagination.page"
            :per-page="pagination.limit"
            :total-rows="pagination.totalRow"
            aria-controls="my-table"
            @change="getTransaction"
          ></b-pagination>
        </div>
        <!-- End Pagination  -->
      </div>
    </div>
    <!-- End List  -->
    <!-- End Content  -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  GET_TRANSACTION,
  EXPORT_DATA
} from "@/core/services/store/transaction.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  mixins: [globalMixin],
  data() {
    return {
      fields: [
        {
          key: "sn",
          label: this.$t("TABLE.TRX_ID"),
          tdClass: "align-middle break-column",
          thClass: "text-center"
        },
        {
          key: "type",
          label: this.$t("TABLE.TYPE"),
          tdClass: "align-middle break-column",
          thClass: "text-center"
        },
        {
          key: "sender_address",
          label: this.$t("TABLE.SENDER_ADDRESS"),
          tdClass: "align-middle break-column",
          thClass: "text-center"
        },
        {
          key: "amount",
          label: this.$t("TABLE.AMOUNT"),
          tdClass: "text-right align-middle",
          thClass: "text-center"
        },
        {
          key: "networks",
          label: this.$t("TABLE.NETWORK"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "token_type",
          label: this.$t("TABLE.TOKEN_TYPE"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "token_name",
          label: this.$t("TABLE.TOKEN_NAME"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "status",
          label: this.$t("TABLE.STATUS"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "date_time",
          label: this.$t("TABLE.CREATED_AT"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        }
      ],
      items: [],
      search: {
        search: "",
        date_from: this.$moment().startOf("month").format("YYYY-MM-DD"),
        date_to: this.$moment().format("YYYY-MM-DD"),
        type: "ALL",
        status: "ALL",
        token_type: "ALL",
        token_name: "ALL",
        sender_address: ""
      },
      searchResult: {},
      pagination: {
        limit: 50,
        page: 1,
        totalRow: 0
      }
    };
  },
  mounted() {
    this.getTransaction();
  },
  methods: {
    exportMethod() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(EXPORT_DATA, {
          limit: this.pagination.limit,
          page: this.pagination.page,
          ...this.searchResult
        })
        .then((e) => {
          const url = window.URL.createObjectURL(new Blob([e]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "recent-transaction.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    getTransaction(page) {
      if (page) {
        this.pagination.page = page;
      }

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_TRANSACTION, {
          limit: this.pagination.limit,
          page: this.pagination.page,
          search: this.search.search,
          type: this.search.type,
          status: this.search.status,
          date_from: this.search.date_from,
          date_to: this.search.date_to,
          token_type: this.search.token_type,
          token_name: this.search.token_name,
          sender_address: this.search.sender_address
        })
        .then((e) => {
          this.items = e.result;
          this.pagination.totalRow = e.totalRow;
          this.pagination.limit = e.limit;

          // Set search for export
          this.searchResult = Object.assign({}, this.search);
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    }
  }
};
</script>
<style scoped lang="scss">
.page-title {
  min-height: auto !important;
}

::v-deep .break-column {
  word-break: break-all;
}
</style>
